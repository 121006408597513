@import "module";

#__next {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  width: 100%;
  margin: auto;
  min-height: 100vh;

  & > main {
    padding-top: 83px;
    scroll-padding-top: 95px;
  }

  .component-anchor {
    pointer-events: none;
    position: absolute;
    top: -83px;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  a {
    color: inherit;
    text-decoration: none;
  }
}

h1,
.h1 {
  margin-bottom: $spacer * 1.5;
  font-family: var(#{--bs-font-family-h1});
  font-weight: var(#{--bs-font-weight-h1});
}

h2,
.h2 {
  margin-bottom: $spacer * 1.5;
  font-family: var(#{--bs-font-family-h2});
  font-weight: var(#{--bs-font-weight-h2});
}

h3,
.h3 {
  margin-bottom: $spacer * 1.5;
  font-family: var(#{--bs-font-family-h3});
  font-weight: var(#{--bs-font-weight-h3});
}

h4,
.h4 {
  font-family: var(#{--bs-font-family-h4});
  font-weight: var(#{--bs-font-weight-h4});
}

h5,
.h5 {
  font-family: var(#{--bs-font-family-h5});
  font-weight: var(#{--bs-font-weight-h5});
}

h6,
.h6 {
  font-family: var(#{--bs-font-family-h6});
  font-weight: var(#{--bs-font-weight-h6});
}

.mw {
  &-sm {
    max-width: map-get($container-max-widths, "sm");
  }

  &-md {
    max-width: map-get($container-max-widths, "md");
  }

  &-lg {
    max-width: map-get($container-max-widths, "lg");
  }

  &-xl {
    max-width: map-get($container-max-widths, "xl");
  }

  &-xxl {
    max-width: map-get($container-max-widths, "xxl");
  }
}

.text {
  &-white {
    a {
      color: $white;
    }
    svg {
      fill: $white;
      stroke: $white;
    }
  }
  &-dark {
    a {
      color: $dark;
    }
    svg {
      fill: $dark;
      stroke: $dark;
    }
  }
  &-primary {
    a {
      color: var(#{--bs-primary});
    }
    svg {
      fill: var(#{--bs-primary});
      stroke: var(#{--bs-primary});
    }
  }
  &-secondary {
    a {
      color: var(#{--bs-secondary});
    }
    svg {
      fill: var(#{--bs-secondary});
      stroke: var(#{--bs-secondary});
    }
  }
  &-tertiary {
    a {
      color: var(#{--bs-tertiary});
    }
    svg {
      fill: var(#{--bs-tertiary});
      stroke: var(#{--bs-tertiary});
    }
  }
  &-quaternary {
    a {
      color: var(#{--bs-quaternary});
    }
    svg {
      fill: var(#{--bs-quaternary});
      stroke: var(#{--bs-quaternary});
    }
  }
}

li,p {
  a {
    color: var(#{--bs-primary});
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

details {
  summary {
    font-weight: $font-weight-bold;
    text-transform: capitalize;
  }
}

b,
strong {
  font-weight: $font-weight-semibold;
}

hr {
  opacity: 1;
  border-color: #C8CDCD;
}

[data-sourceflow-path]:not(img):empty {
  display: none;
}

.sr-only {
  display: none;
}

.btn {
  --bs-btn-font-family: var(#{--bs-font-family-button});
  --bs-btn-border-radius: 0;

  &-block {
    width: 100%;
  }

  &-outline {
    &-primary {
      --bs-btn-color: var(#{--bs-primary}) !important;
      --bs-btn-border-color: var(#{--bs-primary}) !important;
      --bs-btn-hover-bg: var(#{--bs-primary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-primary}) !important;
      --bs-btn-active-bg: var(#{--bs-primary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-primary-active}) !important;

      svg {
        transition: $transition-base;
        stroke: var(#{--bs-primary});
      }

      &:hover {
        svg {
          stroke: #fff;
        }
      }
    }

    &-secondary {
      --bs-btn-color: var(#{--bs-secondary}) !important;
      --bs-btn-border-color: var(#{--bs-secondary}) !important;
      --bs-btn-hover-bg: var(#{--bs-secondary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-secondary}) !important;
      --bs-btn-active-bg: var(#{--bs-secondary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-secondary-active}) !important;
      color: var(#{--bs-dark}) !important;

      svg {
        stroke: var(#{--bs-dark}) !important;
        fill: var(#{--bs-dark}) !important;
        transition: $transition-base;
      }
    }

    &-tertiary {
      --bs-btn-color: var(#{--bs-tertiary}) !important;
      --bs-btn-border-color: var(#{--bs-tertiary}) !important;
      --bs-btn-hover-bg: var(#{--bs-tertiary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-tertiary}) !important;
      --bs-btn-active-bg: var(#{--bs-tertiary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-tertiary-active}) !important;
      color: var(#{--bs-tertiary}) !important;

      svg {
        transition: $transition-base;
        stroke: var(#{--bs-tertiary});
        fill: var(#{--bs-tertiary});
      }

      &:hover {
        color: $white !important;

        svg {
          stroke: $white;
          fill: $white;
        }
      }
    }

    &-quaternary {
      --bs-btn-color: var(#{--bs-quaternary}) !important;
      --bs-btn-border-color: var(#{--bs-quaternary}) !important;
      --bs-btn-hover-bg: var(#{--bs-quaternary}) !important;
      --bs-btn-hover-border-color: var(#{--bs-quaternary}) !important;
      --bs-btn-active-bg: var(#{--bs-quaternary-active}) !important;
      --bs-btn-active-border-color: var(#{--bs-quaternary-active}) !important;
      color: var(#{--bs-dark}) !important;

      svg {
        stroke: var(#{--bs-dark}) !important;
        fill: var(#{--bs-dark}) !important;
        transition: $transition-base;
      }
    }
  }

  &-primary {
    --bs-btn-bg: var(#{--bs-primary}) !important;
    --bs-btn-border-color: var(#{--bs-primary}) !important;
    --bs-btn-hover-bg: var(#{--bs-primary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-primary-active}) !important;
    --bs-btn-hover-color: $white;
    --bs-btn-active-bg: var(#{--bs-primary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-primary-active}) !important;
    --bs-btn-disabled-bg: var(#{--bs-primary}) !important;
    --bs-btn-disabled-border-color: var(#{--bs-primary}) !important;
    color: var(#{--bs-dark}) !important;

    svg {
      stroke: var(#{--bs-dark}) !important;
      fill: var(#{--bs-dark}) !important;
      transition: $transition-base;
    }

    &:hover,
    &:focus {
      color: $white !important;

      svg {
        stroke: $white !important;
        fill: $white !important;
      }
    }
  }

  &-secondary {
    --bs-btn-bg: var(#{--bs-secondary}) !important;
    --bs-btn-border-color: var(#{--bs-secondary}) !important;
    --bs-btn-hover-bg: var(#{--bs-secondary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-secondary-active}) !important;
    --bs-btn-active-bg: var(#{--bs-secondary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-secondary-active}) !important;
    --bs-btn-disabled-bg: var(#{--bs-secondary}) !important;
    --bs-btn-disabled-border-color: var(#{--bs-secondary}) !important;
    color: $white !important;

    svg {
      stroke: $white !important;
      fill: $white !important;
    }
  }

  &-tertiary {
    --bs-btn-bg: var(#{--bs-tertiary}) !important;
    --bs-btn-border-color: var(#{--bs-tertiary}) !important;
    --bs-btn-hover-bg: var(#{--bs-tertiary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-tertiary-active}) !important;
    --bs-btn-active-bg: var(#{--bs-tertiary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-tertiary-active}) !important;
    --bs-btn-disabled-bg: var(#{--bs-tertiary}) !important;
    --bs-btn-disabled-border-color: var(#{--bs-tertiary}) !important;
    color: $white !important;

    svg {
      stroke: $white !important;
      fill: $white !important;
    }
  }

  &-quaternary {
    --bs-btn-bg: var(#{--bs-quaternary}) !important;
    --bs-btn-border-color: var(#{--bs-quaternary}) !important;
    --bs-btn-hover-bg: var(#{--bs-quaternary-active}) !important;
    --bs-btn-hover-border-color: var(#{--bs-quaternary-active}) !important;
    --bs-btn-active-bg: var(#{--bs-quaternary-active}) !important;
    --bs-btn-active-border-color: var(#{--bs-quaternary-active}) !important;
    --bs-btn-disabled-bg: var(#{--bs-quaternary}) !important;
    --bs-btn-disabled-border-color: var(#{--bs-quaternary}) !important;
    color: $white !important;

    svg {
      stroke: $white !important;
      fill: $white !important;
    }
  }
}

.hover-bg {
  &-primary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-primary}) !important;
    }
  }
  &-secondary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-secondary}) !important;
    }
  }
  &-tertiary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-tertiary}) !important;
    }
  }
  &-quaternary {
    transition: $transition-base;

    &:hover,
    &:focus {
      background-color: var(#{--bs-quaternary}) !important;
    }
  }
}

.form {
  &-label {
    font-weight: $font-weight-normal;
  }

  &-select,
  &-control {
    border-radius: 0;
    padding: $spacer * .75 $spacer;
  }

  &-check-input {
    &:checked {
      background-color: var(#{--bs-primary});
      border-color: var(#{--bs-primary});
    }
  }
}

.card {
  --bs-card-border-radius: 0;
  --bs-card-inner-border-radius: 0;
  border: 0;
  overflow: hidden;

  &-header {
    border-bottom: 0;
    font-weight: $font-weight-semibold;
    padding: $spacer $spacer * 2;
    background-color: var(#{--bs-primary});
    color: $white;

    & > * {
      font-size: inherit;
      font-weight: inherit;
      margin: 0;
    }
  }

  &-body {
    border: var(--bs-card-border-width) solid var(--bs-card-border-color);
    border-top: 0;
    border-bottom-left-radius: var(#{--bs-border-radius-sm});
    border-bottom-right-radius: var(#{--bs-border-radius-sm});
    padding: $spacer $spacer $spacer * 2 $spacer;

    @include media-breakpoint-up(md) {
      padding: $spacer * 1.5;
    }
  }
}

.carousel {
  &-item {
    background-color: transparent;
  }
}

.breadcrumb {
  --bs-breadcrumb-divider: ''
}
